import { debounce } from "debounce";
import axios from "axios";

export default function() {
  var searchInput = $(".js-search-input")[0];
  var searchResults = $(".js-search-results")[0];
  var searchView = $(".js-search-view")[0];

  var init = function() {
    addEventListeners();
  };

  var getSearch = function(e) {
    var query = this.value;
    axios
      .get("/search", {
        params: {
          query: query
        }
      })
      .then(function(response) {
        if (response && response.data && response.data.length > 0) {
          renderResults(response.data);
        } else {
          searchResults.classList.remove("is-visible");
        }
      });
  };

  var renderResults = function(results) {
    console.log(results);
    searchResults.classList.add("is-visible");
    searchView.innerHTML = "";
    results.forEach(result => {
      var tr = document.createElement("tr");
      tr.classList.add("result__row");
      tr.style.borderColor = result[4];

      var manual = document.createElement("td");
      manual.innerHTML = result[3];
      manual.classList.add("result__rank");

      var command = document.createElement("td");
      command.classList.add("result__command");

      var commandLink = document.createElement("a");
      commandLink.href = `/${result[3]}/${result[1]}`;
      commandLink.innerHTML = result[1];
      command.appendChild(commandLink);

      var description = document.createElement("td");
      description.innerHTML = result[2];
      description.classList.add("result__description");

      tr.appendChild(manual);
      tr.appendChild(command);
      tr.appendChild(description);

      searchView.appendChild(tr);
    });
  };

  var addEventListeners = function() {
    searchInput.on("input", debounce(getSearch, 400));
  };

  init();
}
