import halftone from "./halftone";

export default function index() {
  if (document.body.classList.contains("index")) {
    console.log("index");

    var init = function() {
      var img = $(".img")[0];

      halftone();

      new BreathingHalftone(img, {
        dotSize: 1 / 60,
        isAdditive: true,
        channels: ["lum"],
        oscPeriod: 1,
        activeForce: 0,

        initVelocity: 0.01,
        // speed at which dots initially grow

        oscPeriod: 10,
        // duration in seconds of a cycle of dot size oscilliation or 'breathing'

        oscAmplitude: 0.1
        // percentage of change of oscillation
        // options...
      });

      addEventListeners();
    };

    var addEventListeners = function() {};

    init();
  }
}
