export default function() {
  document.addEventListener("DOMContentLoaded", function() {
    setTimeout(function() {
      document.body.classList.remove("preload");
    }, 350);

    // Hide Referenced By link until all commands online
    var refLinks = document.querySelectorAll('a[href="#referenced-by"]');
    refLinks.forEach(function(link) {
      link.style.display = "none";
    });
  });
}
