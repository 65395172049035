import preload from "../custom/preload";
import nav from "../custom/nav";
import search from "../custom/search";
import index from "../custom/index";
import section from "../custom/section";
import command from "../custom/command";

preload();
nav();
search();

// Page specific
index();
section();
command();
