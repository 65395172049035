export default function() {
  var navButton = $(".js-nav-button");
  var nav = $(".js-nav-modal")[0];
  var searchInput = $(".js-search-input")[0];

  var isOpen = false;

  var init = function() {
    addEventListeners();
  };

  var triggerNav = function(e) {
    e.preventDefault();

    if (isOpen) {
      document.body.classList.remove("is-open");
      nav.classList.remove("is-open");
      setTimeout(function() {
        nav.classList.remove("is-visible");
      }, 400);
      isOpen = false;
    } else {
      document.body.classList.add("is-open");
      nav.classList.add("is-open");
      nav.classList.add("is-visible");
      isOpen = true;
      setTimeout(() => {
        searchInput.focus();
      }, 400);
    }
  };

  var addEventListeners = function() {
    navButton.on("click", triggerNav);
  };

  init();
}
