import halftone from "./halftone";
import fitty from "fitty";
import WebFont from "webfontloader";

export default function() {
  if (document.body.classList.contains("command")) {
    console.log("section");

    var init = function() {
      var url = window.location.href.replace(/\/$/, "");
      var command = url.substr(url.lastIndexOf("/") + 1);

      var dim = 700;

      var canvas = $(".canvas")[0];
      canvas.width = dim * 1.4;
      canvas.height = dim * 0.8;

      var ctx = canvas.getContext("2d");

      var grd = ctx.createLinearGradient(0, 0, 0, dim);
      grd.addColorStop(0, "#B8B8B8");
      grd.addColorStop(1, "#2E2E2E");

      var grdStrk = ctx.createRadialGradient(
        dim / 2,
        dim / 2,
        5,
        dim / 2,
        dim / 2,
        dim / 2
      );
      grdStrk.addColorStop(0, "#353535");
      grdStrk.addColorStop(0.8, "#353535");
      grdStrk.addColorStop(1, "#000000");

      // ctx.font = dim * 1.28 + "px Atilla Sans";
      ctx.font = dim * 0.7 + "px Atilla Sans";
      ctx.fillStyle = grd;
      ctx.fillText(command, 0, dim - 300);

      ctx.lineWidth = dim / 35;
      ctx.strokeStyle = grdStrk;
      ctx.strokeText(command, 0, dim - 300);

      // Create an image
      var image = canvas.toDataURL();
      var img = $(".img")[0];
      img.src = image;

      // Create halftone
      createHalftone(img);

      // Fit text to window
      fitty(".js-fitty", { maxSize: 200 });
      addEventListeners();
    };

    var createHalftone = function(image) {
      halftone();
      new BreathingHalftone(image, {
        dotSize: 1 / 60,
        isAdditive: true,
        channels: ["lum"],
        oscPeriod: 1,
        activeForce: 0,

        initVelocity: 0.01,
        // speed at which dots initially grow

        oscPeriod: 10,
        // duration in seconds of a cycle of dot size oscilliation or 'breathing'

        oscAmplitude: 0.1
        // percentage of change of oscillation
        // options...
      });
    };

    var addEventListeners = function() {};

    WebFont.load({
      custom: {
        families: ["Atilla Sans"]
      },
      active: function() {
        init();
      }
    });
  }
}
